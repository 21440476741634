import './Divider.css';

export function Divider({ text }) {
  return (
    <div id="DividerContainer">
      <div className="wrapper">
        <div>{text}</div>
        <div className="divider div-transparent div-arrow-down"></div>
        {/* <div class="divider div-transparent"></div>
      <div class="divider div-transparent div-tab-down"></div>
      <div class="divider div-transparent div-stopper"></div>
      <div class="divider div-transparent div-dot"></div> */}
      </div>
    </div>
  );
}

export default Divider;
