import logo from '../../images/blue_logo.png';
import { AiOutlineArrowUp } from 'react-icons/ai';
import { BsWhatsapp } from 'react-icons/bs';
import { MdLocationOn } from 'react-icons/md';
import { IoIosMailOpen } from 'react-icons/io';
import { IconContext } from 'react-icons';
import { TbWorld } from "react-icons/tb";
import './Footer.css';

export function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <IconContext.Provider value={{ className: 'footerIcons' }}>
      <footer id="footer">
        <div id="mainContainerFooter">
          <div className="row py-4" id='firstRow'>
            <div className=" col-md-8">

              <div className='row'>
                <div className='col-md-2'>
                  <a href="/">
                    <img id="FooterLogo" src={logo} alt="Logo" />
                  </a>
                </div>
                <div className='col-md-10' style={{ alignSelf: 'flex-end' }} >
                  <div id="locationInfo">
                    <div>
                      <a href="https://wa.me/+573005088800">
                        <BsWhatsapp /> +57 (300) 508 - 8800
                      </a>
                    </div>
                    <div>
                      <a href="mailto:contactenos@leonabogados.co">
                        <IoIosMailOpen />
                        contactenos@leonabogados.co
                      </a>
                    </div>
                    <div>
                      <a href="https://www.leonabogados.co">
                        <TbWorld />
                        www.leonabogados.co
                      </a>
                    </div>
                    <div>

                      <MdLocationOn />
                      Bogotá D.C. - Cartagena de Indias, D.T. y C. Colombia
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="col-md-4" id='upButtonContainer'>
              <button type="button" id="footerButton" onClick={scrollToTop}>
                Volver arriba
                <AiOutlineArrowUp />
              </button>
            </div>
          </div>


          <div className="row py-2" id='copyRightFooter'>
            <div
              className="py-3 col-sm-12 col-md-12 col-lg-12 col-xxl-6"
              style={{ whiteSpace: 'normal' }}
            >
              © 2024 Copyright
              <a className="text-decoration-none text-reset" href="/">
                : León Abogados todos los derechos reservados
              </a>
            </div>
            <div className="py-2 col-sm-12 col-md-12 col-lg-12 col-xxl-6 d-flex copyRightLinksFooter">
              <ul className="list-group" id="copyRightListFooter">
                <li className="py-2">
                  <a
                    className="text-decoration-none text-reset"
                    target="_blank"
                    rel="noreferrer"
                    href="#!"
                  >
                    Política de privacidad de datos
                  </a>
                </li>
                <li className="py-2">
                  <a className="text-decoration-none text-reset" href="#!">
                    Términos legales y condiciones de contratación
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </IconContext.Provider >
  );
}

export default Footer;
