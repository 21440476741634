import heroImage from './team.jpg';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import './PrivacyPolicies.css';

export function PrivacyPolicies() {
  return (
    <>
      <img src={heroImage} alt="Equipo" id="backgroundImage" />
      <div id="middleSpace" className="d-flex d-flex align-items-center">
        <div id="heroTitle">
          <h2>Política de Privacidad.</h2>
        </div>
      </div>
      <div id="pageMainContainer" className="d-flex justify-content-center">
        <div id="pageContainer">
          <div id="breadCrumbContainer">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
              <Breadcrumb.Item href="./privacyPolicies">
                Política de Privacidad.
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="privacyPolicies-card col-sm-12 col-md-8">
              <div className="privacyPolicies-texts">
                <div className="privacyPolicies-texts privacyPolicies-title">
                  NOTA DE AUTORIZACIÓN:
                </div>
                <div className="privacyPolicies-texts privacyPolicies">
                  <p>
                    Declaro bajo la gravedad de juramento que todos los datos aquí
                    contenidos son exactos y veraces y que LEÓN ABOGADOS & Co., NIT.
                    901.623.582-7, he verificado de manera previa y expresa las políticas
                    de protección de datos, los derechos que me asisten y la finalidad,
                    tratamiento, vigencia que se le dará a mis datos personales.
                  </p>
                  <p>
                    En consecuencia, de lo anterior, autorizo expresamente de manera
                    libre, previa, voluntaria y debidamente informada, a LEÓN ABOGADOS &
                    Co. con NIT. 901.623.582-7, para que continúe con el Tratamiento de
                    mis datos, de acuerdo con las finalidades y condiciones mencionadas en
                    el aviso de privacidad que declaro conocer y acepta
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicies;
