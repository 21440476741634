import heroImage from './Contacto.jpg';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import emailjs from 'emailjs-com';

import { useRef } from 'react';

import './Contact.css';

export function Contact() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_bi08gan', 'template_avo3o1a', form.current, '2EwDWWwAz1Wfw0szF')
      .then(
        (result) => {
          console.log(result.text);
          alert(
            'Gracias por entrar en contacto con León Abogados, a la mayor brevedad posible un abogado estará escribiéndole. La recepción de los mensajes no implica aceptación.'
          );
        },
        (error) => {
          console.log(error.text);
          alert('Error', error);
        }
      );
  };

  return (
    <>
      <img src={heroImage} alt="Equipo" id="backgroundImage" />
      <div id="middleSpace" className="d-flex align-items-center">
        <div id="heroTitle">
          <h2>NOS ESTAMOS RENOVANDO</h2>
        </div>
      </div>
      <div id="pageContactContainer" className="d-flex justify-content-center">
        <div id="pageContainer">
          <div id="breadCrumbContactContainer" style={{ color: 'white' }}>
            <Breadcrumb>
              <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
              <Breadcrumb.Item href="./Contact">Contacto</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="row">
            <div className="col-md-6 contactDescription">
              <div className="">
                <p>
                  En LEÓN ABOGADOS nos estamos renovando para estar más cerca de Ustedes; nuestro compromiso por brindar el mejor servicio continua, el cual se logra a través de la comunicación y el apoyo experimentado desde el primer contacto.
                </p>
                <p>
                  Por ello lo invitamos a escribirnos a través de este formulario si tiene alguna consulta o desea realizar algún acercamiento.
                </p>
                <p>
                  Al enviar su información de contacto, usted reconoce que ha leído la política de privacidad y datos personales y que usted autoriza la utilización de sus datos de acuerdo con la legislación vigente.
                </p>
              </div>
            </div>

            <div className="col-md-6 ">
              <div className="col align-self-center">
                <form ref={form} onSubmit={sendEmail}>
                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="name">Nombre y apellido</label>
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          id="name"
                          placeholder="Nombre y apellido"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          id="email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group">
                        <label htmlFor="company">Empresa/Firma</label>
                        <input
                          type="text"
                          name="company"
                          className="form-control"
                          id="company"
                          placeholder="Empresa/Firma"
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="tel">Teléfono</label>
                        <input
                          type="text"
                          name="tel"
                          className="form-control"
                          placeholder="Números de teléfono"
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="typeOfCounseling">Tipo de asesoría</label>
                      <select
                        name="typeOfCounseling"
                        className="form-control"
                        id="typeOfCounseling"
                        style={{ appearance: 'auto' }}
                      >
                        <option value="Estoy interesado en sus servicios">
                          Estoy interesado en sus servicios
                        </option>
                        <option value="Soy periodista y realizo una investigación/reportaje">
                          Soy periodista y realizo una investigación/reportaje
                        </option>
                        <option value="Soy contraparte y quiero tener un acercamiento">
                          Soy contraparte y quiero tener un acercamiento
                        </option>
                        <option value="otro">otro</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="message">Mensaje</label>
                      <textarea
                        className="form-control"
                        id="message"
                        name="message"
                        rows="5"
                      ></textarea>
                    </div>
                  </div>
                  <label htmlFor="checkbox" className="privacyPolicies">
                    <input
                      name="checkbox"
                      id="privacyPoliciesCheckbox"
                      required
                      type="checkbox"
                    />
                    Acepto el tratamiento de datos según la
                    <a
                      id="privacyPoliciesLink"
                      className="text-decoration-none text-reset"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Política de Privacidad.
                    </a>
                  </label>

                  <button type="submit" id="ContactButton" className="btn-right">
                    Enviar
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
