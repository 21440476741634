import { Routes, Route, Outlet } from 'react-router-dom';
import {
  NotFoundPage,
  Contact,
} from '../pages';
import { Nabvar } from '../components/Nabvar/Nabvar';
import { Footer } from '../components/Footer/Footer';

export const AppRouter = () => {
  return (
    <>
      <Nabvar />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Contact />} />
          <Route path="*" element={<Contact />} />
        </Route>
      </Routes>
      <Footer />
    </>
  );
};

function Layout() {
  return <Outlet />;
}

export default AppRouter;
