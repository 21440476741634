import heroImage from './Equipo.jpg';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import angieCentered from './angie_centered.jpg';
import arnulfoCentered from './arnulfo_centered.jpg';
import davidCentered from './david_centered.jpg';

import './People.css';

export function People() {
  //images 2800 x 1580
  return (
    <>
      <img src={heroImage} alt="Equipo" id="backgroundImage" />
      <div id="middleSpace" className="d-flex align-items-center">
        <div id="heroTitle">
          <h2>Equipo</h2>
        </div>
      </div>
      <div id="pageMainContainer" className="d-flex justify-content-center">
        <div id="pageContainer">
          <div id="breadCrumbContainer">
            <Breadcrumb>
              <Breadcrumb.Item href="/">Inicio</Breadcrumb.Item>
              <Breadcrumb.Item href="./People">Equipo</Breadcrumb.Item>
            </Breadcrumb>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="people-card col-sm-12 col-md-4 ">
              <a href="./david-mauricio">
                <img src={davidCentered} alt="Logo" />
                <div className="people-texts">
                  <div className="people-texts people-name">
                    David Mauricio León Forero
                  </div>
                  <div className="people-texts people-position">Managing Partner</div>
                  <div className="people-texts people-specialty">
                    Gerente Unidad de Controversias y Litigios
                  </div>
                </div>
              </a>
            </div>
            <div className="people-card col-sm-12 col-md-4 ">
              <a href="./angie-lopez">
                <img src={angieCentered} alt="Logo" />
                <div className="people-texts">
                  <div className="people-texts people-name">Angie Paola López Medina</div>
                  <div className="people-texts people-position">Partner</div>
                  <div className="people-texts people-specialty">
                    Gerente Unidad de Prevención de Controversias en Derecho Público y
                    Relación Estatal
                  </div>
                </div>
              </a>
            </div>

            <div className="people-card col-sm-12 col-md-4 ">
              <a href="./arnulfo-cruz">
                <img src={arnulfoCentered} alt="Logo" />
                <div className="people-texts">
                  <div className="people-texts people-name">Arnulfo Cruz Baquero</div>
                  <div className="people-texts people-position">Partner</div>
                  <div className="people-texts people-specialty">
                    Gerente Unidad de Prevención de Controversias en Derecho Privado y
                    Patrimonios
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default People;
