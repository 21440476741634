import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import './Nabvar.css';


import logo from '../../images/blue_logo.png';
export function Nabvar() {

  return (
    <Navbar sticky="top" collapseOnSelect expand="lg" id="navBar">
      <Container id="mainNabvarCointainer" className="d-flex align-items-end">
        <a href="/">
          <img id="nabvarLogo" src={logo} alt="Logo" />
        </a>
      </Container>
    </Navbar>
  );
}

export default Nabvar;
